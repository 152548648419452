import React from "react";
import Header from "./AppBar";
import Hero from "./Hero";
import Footer from "./Footer";

const Landing = () => {
  return (
    <>
      <Header />
      <Hero />
      <Footer />
    </>
  );
};

export default Landing;
