import React from "react";
import { styled } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";
import {
  AppBar as MuiAppBar,
  Button,
  Toolbar as MuiToolbar,
  Box,
} from "@mui/material";
import { APP_DETAILS, ROUTES } from "../../../constants";
import useBreakpoints from "../../../hooks/useBreakpoints";
import UserDropdown from "../../../components/UserDropdown";
import { useHistory } from "react-router-dom";
import { BrandIcon } from "../../../components/Sidebar";

// Styled Components
const AppBar = styled(MuiAppBar)(({ theme }) => ({
  background: theme.header.background,
  color: theme.header.color,
}));

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    minHeight: 96,
  },
  [theme.breakpoints.down("sm")]: {
    minHeight: 64,
  },
}));

function AppBarComponent() {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const history = useHistory();
  const { isXs, isSm, isUpMd } = useBreakpoints();

  const handleButtonClick = () => {
    if (isAuthenticated) {
      history.push(ROUTES.MAP);
    } else {
      loginWithRedirect({ appState: { returnTo: ROUTES.MAP } });
    }
  };

  return (
    <>
      <AppBar position="fixed" elevation={0}>
        <Toolbar>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Box display="flex" alignItems="center">
              <BrandIcon
                src={APP_DETAILS.ICONS.LOGO_FULL_LONG_WHITE}
                height={isUpMd ? "50px" : "40px"}
                alt={APP_DETAILS.SHORT_NAME}
                style={{ marginLeft: "16px" }}
              />
            </Box>
            <Box display="flex" alignItems="center" gap={3}>
              <Button
                sx={{ mr: 1 }}
                size={isXs ? "small" : isSm ? "medium" : "large"}
                color="secondary"
                variant="contained"
                onClick={handleButtonClick}
              >
                {isAuthenticated
                  ? isXs
                    ? "Dashboard"
                    : "View Dashboard"
                  : isXs
                  ? "Log in"
                  : "Log in to Dashboard"}
              </Button>
              {isAuthenticated && <UserDropdown />}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar /> {/* Spacer Toolbar */}
    </>
  );
}

export default AppBarComponent;
