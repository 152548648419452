export const APP_DETAILS = {
  TITLE: "Williamson County Dashboard",
  SHORT_TITLE: "Williamson County Dashboard",
  NAME: "Williamson County",
  SHORT_NAME: "Williamson County",
  ABBREVIATED_NAME: "Wilco",
  ICONS: {
    LOGO_FULL_LONG_WHITE:
      "/static/img/branding/williamsonco-logo-full-long-white.png",
    LOGO_FULL_WHITE: "/static/img/branding/williamsonco-logo-full-white.png",
    LOGO_FULL_COLOR: "/static/img/branding/williamsonco-logo-full-color.png",
    LOGO_FULL_COLOR_WHITE_BG:
      "/static/img/branding/williamsonco-logo-full-color-white-bg.png",
    LOGO_SIMPLE_WHITE:
      "/static/img/branding/williamsonco-logo-simple-white.png",
    LOGO_SIMPLE_COLOR:
      "/static/img/branding/williamsonco-logo-simple-color.png",
  },
  URL: "https://www.wilcotx.gov/",
};

export const ROUTES = {
  LANDING: "/",
  MAP: "/map",
  USER_LOGOUT: "/auth/logout",
  SPLASH: "/login",
};

export const APP_THEME = {
  LAYOUT: {
    SIDEBAR_WIDTH: 258,
  },
  DATE_FORMATS: {
    LONG: "MMMM do yyyy, h:mma",
    SHORT: "MM/dd/yyyy",
  },
  TYPOGRAPHY: {
    HEADER_FONT: "Arial, sans-serif",
  },
};

export const MUI_THEME = {
  COLORS: {
    PRIMARY: "#3e485b",
    SECONDARY: "#2F9BA9",
  },
};

export const REACT_QUERY_CONFIG = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
};

export const CRUD_LOOKUP_TABLES = [];
