import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { memo } from "react";
import { BASEMAP_STYLES, BASEMMAP_IMAGE_CENTER } from "../../constants";

/**
 * Utility function used to leverage the Mapbox static map API and return
 * a basemap preview for the specified style
 */
const getBasemapImage = (style) => {
  return `https://api.mapbox.com/styles/v1/mapbox/${style}/static/${BASEMMAP_IMAGE_CENTER[0]},${BASEMMAP_IMAGE_CENTER[1]},9,0/180x100@2x/?attribution=false&logo=false&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`;
};

const BasemapItems = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(3),
  flexWrap: "wrap",
}));

const BasemapItem = styled("div")({
  width: "calc(50% - 8px)",
});

const BasemapPreview = styled("div")(({ imageUrl, active, theme }) => ({
  background: `url("${imageUrl}")`,
  backgroundSize: "cover",
  border: `1px solid ${active ? theme.palette.primary.main : "#ddd"}`,
  borderRadius: "4px",
  cursor: "pointer",
  height: "100px",
  "&:hover": {
    borderColor: theme.palette.primary.main,
  },
}));

const BasemapsControl = ({ onBasemapChange, value, filters }) => {
  return (
    <Box p={1} mb={2}>
      <BasemapItems>
        {BASEMAP_STYLES?.map((item) => {
          return (
            <BasemapItem
              key={item.name}
              onClick={() => onBasemapChange(item, filters)}
            >
              <Typography variant="body2" gutterBottom>
                {item.name}
              </Typography>
              <BasemapPreview
                active={item.style === value}
                imageUrl={getBasemapImage(item.style)}
              />
            </BasemapItem>
          );
        })}
      </BasemapItems>
    </Box>
  );
};

export default memo(BasemapsControl);
