import React, { useCallback, useRef } from "react";
import { styled } from "@mui/material/styles";
import { Modal, Box, Typography } from "@mui/material";
import "./styles.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import "../../vendor/perfect-scrollbar.css";

import { INIT_MAP_CONFIG } from "./constants";
import { useApp } from "../../AppProvider";
import { useMap } from "./hooks/useMap";
import useSources from "./hooks/useSources";
import useFilters from "./hooks/useFilters";

import Map from "./Map";
import DisclaimerDialog from "./components/DisclaimerDialog";
import MainControl from "./controls/MainControl/";
import PopupControl from "./controls/PopupControl";
import Loader from "../../components/Loader";
import MapDevTools from "./controls/MapDevTools";
import Paper from "@mui/material/Paper";
import { Helmet } from "react-helmet-async";
import Search from "./filters/Search";
import FilterControl from "./filters/FilterControl";
import Filter from "./filters/Filter";
import TogglesControl from "./filters/TogglesControl";
import useLayerStyles from "./hooks/useLayerStyles";
import WellStylesControl from "./controls/WellStylesControl";
import MapExportControl from "./components/MapExportControl";

const FiltersBarRoot = styled(Paper)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  gap: theme.spacing(6),
  padding: "12px 16px 22px 40px",
}));

const FiltersSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

const FiltersContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  flex: "1 1 0",
}));

const PublicMap = () => {
  const mapContainerRef = useRef(null);
  const { currentUser } = useApp();
  const { sources } = useSources();

  const {
    map,
    isMapReady,
    activeBasemap,
    layers,
    updateBasemap,
    updateLayerFilters,
    updateLayerStyles,
    updateLayerVisibility,
    updateLayerOpacity,
  } = useMap(mapContainerRef, INIT_MAP_CONFIG, sources);

  const {
    filterValues,
    handleFilterValues,
    handleSelectAll,
    handleSelectNone,
  } = useFilters({ onFilterChange: updateLayerFilters });

  const { activeStyle, handleActiveStyle, styleOptions } = useLayerStyles({
    onLayerStyleChange: updateLayerStyles,
    isMapReady,
    filterValues,
  });

  const handleSearchSelect = useCallback(
    (result) => {
      map?.flyTo({ center: result?.geometry?.coordinates, zoom: 16 });
    },
    [map]
  );

  const renderFilters = (filters, renderComponent) =>
    filters.map((filter, index) => (
      <FilterControl key={index} filter={filter}>
        {renderComponent(filter)}
      </FilterControl>
    ));

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Helmet title="Interactive Map" />
      <Modal open={!isMapReady}>
        <div style={{ height: "100%" }}>
          <Loader size={120} />
        </div>
      </Modal>

      {process.env.NODE_ENV !== "development" && !!sources?.length && (
        <DisclaimerDialog />
      )}

      <div style={{ backgroundColor: "#fff", borderBottom: "1px solid #ddd" }}>
        <PerfectScrollbar>
          <FiltersBarRoot>
            <Search onSelect={handleSearchSelect} sources={sources} />

            <FiltersSection>
              <FiltersContainer>
                {renderFilters(
                  Object.values(filterValues).filter(
                    ({ type, excludeFilter }) =>
                      type === "multi-select" && !excludeFilter
                  ),
                  (filter) => (
                    <Filter
                      onChange={handleFilterValues}
                      onSelectAll={handleSelectAll}
                      onSelectNone={handleSelectNone}
                      filter={filter}
                    />
                  )
                )}
              </FiltersContainer>
            </FiltersSection>

            <FiltersSection>
              <FiltersContainer>
                <TogglesControl
                  filters={Object.values(filterValues).filter(
                    (f) => f.type === "boolean"
                  )}
                >
                  <Box display="flex" flexDirection="column">
                    {Object.values(filterValues)
                      .filter((f) => f.type === "boolean")
                      .map((fv) => (
                        <Filter
                          key={fv.name}
                          onChange={handleFilterValues}
                          filter={fv}
                        />
                      ))}
                  </Box>
                </TogglesControl>
              </FiltersContainer>
            </FiltersSection>

            <FiltersSection>
              <FiltersContainer>
                <FilterControl
                  width="250px"
                  label={
                    activeStyle
                      ? `Color wells by ${activeStyle?.name}`
                      : "Color wells"
                  }
                >
                  <Typography variant="subtitle1" gutterBottom>
                    Color wells by
                  </Typography>
                  <WellStylesControl
                    label="Color wells by"
                    name="wellStyles"
                    onChange={handleActiveStyle}
                    options={styleOptions}
                    value={activeStyle?.id}
                  />
                </FilterControl>
              </FiltersContainer>
            </FiltersSection>

            <MapExportControl map={map} />
          </FiltersBarRoot>
        </PerfectScrollbar>
      </div>

      <Map ref={mapContainerRef}>
        {isMapReady && (
          <>
            <MainControl
              activeBasemap={activeBasemap}
              layers={layers}
              onBasemapChange={updateBasemap}
              filters={filterValues}
              onLayerChange={updateLayerVisibility}
              onOpacityChange={updateLayerOpacity}
            />
          </>
        )}
        <MapDevTools map={map} />
        {(currentUser?.isDeveloper || process.env.NODE_ENV === "development") &&
          !!map && <MapDevTools map={map} />}
        <PopupControl />
      </Map>
    </Box>
  );
};
export default PublicMap;
