import React, { memo, useRef } from "react";
import { Typography, Divider, Box, Grid } from "@mui/material";
import { format } from "date-fns";
import { APP_DETAILS, APP_THEME } from "../../../constants";

const PRINTED_MAP_WIDTH = 816 - 32; // For US letter size (8.5 inches * 96 DPI), minus padding (32px)

// Helper function to calculate adjusted scale width based on screen-to-print ratio
const getAdjustedScale = (map, PRINTED_MAP_WIDTH) => {
  const scaleElement = document.querySelector(".mapboxgl-ctrl-scale");
  if (!scaleElement) return null;

  const screenMapWidth = parseFloat(map.getCanvas().style.width);
  const scaleWidth = parseFloat(scaleElement.style.width);
  const scaleRatio = PRINTED_MAP_WIDTH / screenMapWidth;

  return scaleWidth * scaleRatio;
};

const Header = ({ title }) => (
  <Grid
    container
    justifyContent="space-between"
    alignItems="center"
    sx={{ pt: 4 }}
  >
    <Grid item xs={3}>
      <img
        src={APP_DETAILS.ICONS.LOGO_SIMPLE_COLOR}
        height="75px"
        alt={APP_DETAILS.SHORT_NAME}
      />
    </Grid>
    <Grid item xs={6} textAlign="center">
      <Typography variant="h5">{APP_DETAILS.SHORT_NAME} Map</Typography>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="subtitle2">
        Created: {format(new Date(), APP_THEME.DATE_FORMATS.LONG)}
      </Typography>
    </Grid>
    <Grid item xs={3} />
  </Grid>
);

const LREWaterLogo = () => (
  <Grid item xs={2} sx={{ textAlign: "right" }}>
    <img
      src="/static/img/branding/lrewater-logo-full.png"
      width="75px"
      alt="LRE Water"
    />
  </Grid>
);

const PrintMapFormat = ({ title, mapImg, map }) => {
  const adjustedScaleWidth = getAdjustedScale(map, PRINTED_MAP_WIDTH);

  const legendRef = useRef(
    Array.from(document.querySelectorAll(".print-legend"))
  );

  return (
    <Box sx={{ paddingX: 4 }}>
      {/* First Page */}
      <Header title={title} pt={0} />
      <Divider sx={{ marginBottom: 2 }} />

      <Box sx={{ overflow: "hidden", width: "100%", textAlign: "center" }}>
        <img
          src={mapImg}
          style={{
            width: PRINTED_MAP_WIDTH,
            height: "auto",
            objectFit: "contain",
            objectPosition: "left top",
          }}
          alt="Map"
        />
      </Box>

      <Grid
        container
        mt={1}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={3}>
          {adjustedScaleWidth && (
            <div
              className="mapboxgl-ctrl mapboxgl-ctrl-scale"
              style={{ width: `${adjustedScaleWidth}px` }}
            >
              {document.querySelector(".mapboxgl-ctrl-scale").innerText}
            </div>
          )}
        </Grid>
        <Grid item xs={7} />
        <LREWaterLogo />
      </Grid>

      <Box sx={{ pageBreakAfter: "always" }} />

      {/* Second Page */}
      <Header title={title} />
      <Divider sx={{ marginBottom: 2 }} />

      <Grid
        container
        mt={4}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={10}>
          <Grid container>
            {legendRef.current.map((item, index) => (
              <Grid item xs={6} key={index}>
                <span dangerouslySetInnerHTML={{ __html: item.outerHTML }} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <LREWaterLogo />
      </Grid>
    </Box>
  );
};

export default memo(PrintMapFormat);
