import { useCallback, useMemo, useState, useEffect } from "react";
import { scaleOrdinal } from "d3-scale";
import { schemePaired } from "d3-scale-chromatic";
import { CIRCLE_FALLBACK_COLOR, INIT_STYLE_VALUES } from "../../constants";

/**
 * Builds an array that can be used by Mapbox's "match" expression.
 * For example: ["match", ["get", field], "valueA", "colorA", "valueB", "colorB", ...].
 */
function buildColorScale(values) {
  const scale = scaleOrdinal(schemePaired);
  return values.reduce((acc, { display, value, color }) => {
    acc.push(display);
    acc.push(color || scale(value));
    return acc;
  }, []);
}

/**
 * Returns a new style object for "match" or "interpolate" type color expressions.
 */
function getStyleWithPaint(prevState, dataKey, field, data, type = "match") {
  const prevLayer = prevState[dataKey];
  if (!prevLayer) return {};

  // For interpolate layers, we just return the existing paint config
  if (type.includes("interpolate")) {
    return {
      ...prevLayer,
      paint: prevLayer.paint,
    };
  }

  // For match layers, dynamically build the scale
  return {
    ...prevLayer,
    paint: {
      "circle-color": [
        "match",
        ["get", field],
        ...buildColorScale(data),
        CIRCLE_FALLBACK_COLOR, // fallback color
      ],
    },
  };
}

/**
 * Returns a new style object with updated symbol definitions for the legend.
 */
function getStyleWithSymbol(prevState, dataKey, field, data) {
  const prevLayer = prevState[dataKey];
  if (!prevLayer) return {};

  return {
    ...prevLayer,
    lreProperties: {
      ...prevLayer.lreProperties,
      legend: {
        ...prevLayer.lreProperties?.legend,
        symbols: data || [],
      },
    },
  };
}

/**
 * Hook to manage dynamic style changes for layers.
 * Automatically picks a default/fallback active style once the map is ready,
 * and provides a handler to set a new active style.
 */
function useLayerStyles({ onLayerStyleChange, isMapReady, filterValues }) {
  const [activeStyle, setActiveStyle] = useState(null);

  // Build up the style values from filterValues or default configs.
  const styleValues = useMemo(() => {
    if (!filterValues || !isMapReady) return INIT_STYLE_VALUES;

    return Object.keys(INIT_STYLE_VALUES).reduce((acc, key) => {
      const layerConfig = INIT_STYLE_VALUES[key];

      // If category is present and mapGwdbLegend is provided, we use symbol logic
      if (layerConfig.category && filterValues?.mapGwdbLegend) {
        acc[key] = getStyleWithSymbol(
          INIT_STYLE_VALUES,
          key,
          layerConfig.category,
          filterValues.mapGwdbLegend[layerConfig.category]
        );
      }
      // Otherwise, if there's a recognized field and filter data
      else if (layerConfig.layerFieldName && filterValues[key]?.options) {
        // Determine if the paint uses 'interpolate' or 'match'
        const paintColor = layerConfig.paint["circle-color"];
        const isInterpolate =
          Array.isArray(paintColor) && paintColor[0].includes("interpolate");

        acc[key] = getStyleWithPaint(
          INIT_STYLE_VALUES,
          key,
          layerConfig.layerFieldName,
          filterValues[key].options,
          isInterpolate ? "interpolate" : "match"
        );
      } else {
        // No special filter logic; just copy the config
        acc[key] = { ...layerConfig };
      }
      return acc;
    }, {});
  }, [filterValues, isMapReady]);

  // Once the map is ready, pick a default or fallback style if none is active.
  useEffect(() => {
    if (activeStyle || !isMapReady) return;

    const stylesArray = Object.values(styleValues);
    const defaultLayer = stylesArray.find((layer) => layer.defaultLoad);
    const fallbackLayer = stylesArray[0]; // or any fallback approach

    const newActiveStyle = defaultLayer || fallbackLayer;
    if (newActiveStyle) {
      setActiveStyle(newActiveStyle);
      onLayerStyleChange(newActiveStyle);
    }
  }, [styleValues, isMapReady, activeStyle, onLayerStyleChange]);

  /**
   * Allows external components to set a new active style by name.
   */
  const handleActiveStyle = useCallback(
    (name) => {
      const selectedStyle = styleValues[name];
      if (!selectedStyle) return;

      setActiveStyle(selectedStyle);
      onLayerStyleChange(selectedStyle);
    },
    [styleValues, onLayerStyleChange]
  );

  /**
   * Format style options for a UI (dropdown or menu) that displays layer names.
   */
  const styleOptions = useMemo(
    () =>
      Object.entries(styleValues).map(([key, value]) => ({
        display: value.name,
        value: key,
      })),
    [styleValues]
  );

  return {
    activeStyle,
    handleActiveStyle,
    styleOptions,
  };
}

export default useLayerStyles;
