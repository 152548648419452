import React, { memo, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Box,
  Paper,
  Typography,
} from "@mui/material";
import LayersIcon from "@mui/icons-material/Layers";
import MapIcon from "@mui/icons-material/Map";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import LayersControl from "../LayersControl";
import BasemapsControl from "../BasemapsControl";
import useBreakpoints from "../../../../hooks/useBreakpoints";

const Container = styled(Paper)(({ width }) => ({
  background: "none",
  boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
  right: 0,
  position: "absolute",
  top: "56px",
  maxHeight: "calc(100% - 130px)",
  overflowX: "hidden",
  overflowY: "scroll",
  width: width,
  zIndex: 3,
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ maxheight }) => ({
  backgroundColor: "#fafafa",
  borderTop: "1px solid #ddd",
  borderBottom: "1px solid #ddd",
  maxHeight: maxheight,
  overflowX: "hidden",
  overflowY: "auto",
}));

const MainControl = ({
  activeBasemap,
  layers,
  onBasemapChange,
  filters,
  onLayerChange,
  onOpacityChange,
}) => {
  const { isSm, isUpMd } = useBreakpoints();

  const [expandedItem, setExpandedItem] = useState(isSm ? false : "layers");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedItem(isExpanded ? panel : false);
  };

  const calculatedWidth =
    isSm && !expandedItem ? "75px" : isSm && expandedItem ? "225px" : "315px";

  return (
    <Container width={calculatedWidth}>
      <Accordion
        expanded={expandedItem === "basemaps"}
        onChange={handleChange("basemaps")}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box alignItems="center" display="flex" columnGap={2}>
            <MapIcon />
            {(isUpMd || expandedItem) && (
              <Typography variant="subtitle1">Basemaps</Typography>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails
        // maxheight={isXs ? "258px" : "384px"}
        >
          <BasemapsControl
            value={activeBasemap}
            onBasemapChange={onBasemapChange}
            filters={filters}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedItem === "layers"}
        onChange={handleChange("layers")}
      >
        <AccordionSummary
          aria-controls="layers-content"
          id="layers-header"
          expandIcon={<ExpandMore />}
        >
          <Box alignItems="center" display="flex" columnGap={2}>
            <LayersIcon />
            {(isUpMd || expandedItem) && (
              <Typography variant="subtitle1">Layers</Typography>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails
        // maxheight={isXs ? "258px" : "384px"}
        >
          <LayersControl
            items={layers}
            onLayerChange={onLayerChange}
            onOpacityChange={onOpacityChange}
          />
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default memo(MainControl);
